@import './colors.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  color: $text-dark-color;
  background: linear-gradient(
    rgba(18, 61, 60, 0.84),
    rgba(244, 229, 185, 0.84)
  );

  @media print {
    background: unset;
  }

  // overrides for mat-tab
  mat-tab-group:not(.g-tabs-with-underline) mat-tab-header.mat-mdc-tab-header {
    border: none;
    .mat-mdc-tab {
      height: 43px;
      font-weight: 400;
      opacity: 1;
      border-radius: 5px 5px 0 0;
      .mdc-tab__text-label {
        color: white;
      }
      // background-color: #454545;
      background-color: rgba(69, 69, 69, 0.15);
      svg {
        fill: white;
      }
      &.mdc-tab--active {
        background-color: $background-light-color;
        // colors of an active tab indicator on hover and focus
        --mdc-tab-indicator-active-indicator-color: #{$background-light-color};
        --mat-tab-header-active-focus-indicator-color: #{$background-light-color};
        --mat-tab-header-active-hover-indicator-color: #{$background-light-color};
        // color when hovering over an active tab
        --mat-tab-header-active-ripple-color: white;
        .mdc-tab__text-label {
          color: $text-dark-color;
        }
        svg {
          fill: $text-dark-color;
        }
      }
    }
    mat-ink-bar {
      display: none;
    }
  }

  mat-tab-group.g-tabs-with-underline {
    .mat-mdc-tab {
      font-family: 'DM Sans', sans-serif;
      font-size: 16px;
    }
    .mat-mdc-tab-nav-bar,
    .mat-mdc-tab-header {
      border-bottom: none;
    }
    .mat-mdc-tab-labels {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  // styling input
  input {
    font-size: 14px;
    height: 21px !important;
  }

  // styling arrow in mat-select
  .mat-mdc-select-arrow {
    color: $primary;
  }

  // customize border color of disabled checkbox
  .mdc-checkbox
    .mdc-checkbox__native-control[disabled]
    ~ .mdc-checkbox__background {
    border-color: $disabled-checkbox-color !important;
  }

  // override for checkbox border color
  .mat-mdc-checkbox.mat-primary {
    .mdc-checkbox__background {
      border-color: $primary !important;
    }
    .mdc-checkbox__checkmark {
      color: white !important;
    }
  }

  // wrap radio button option
  .mat-mdc-radio-button .mdc-form-field .mdc-label {
    white-space: normal;
  }

  .cdk-overlay-dark-backdrop {
    backdrop-filter: blur(2.2px);
  }

  // colors for dialog
  .mat-mdc-dialog-container {
    --mdc-dialog-container-color: #{$background-light-color};
    --mdc-dialog-supporting-text-color: #{$text-dark-color};

    .mdc-dialog__content {
      padding-left: 42px;
      padding-right: 42px;
      max-height: calc(100vh - 48px - 16px);
      @media only screen and (max-width: 599px) {
        padding-left: 32px;
        padding-left: 32px;
      }
    }
  }

  // styling dialog with close button in mat-dialog-title (panelClass)
  .dialog-with-close-button {
    .mdc-dialog__container {
      padding-top: 8px;
      .mat-mdc-dialog-title {
        margin-bottom: 0;
        button.mat-mdc-icon-button {
          right: -16px;
          top: 7px;
        }
      }
    }
    .mdc-dialog__content {
      max-height: calc(100vh - 72px - 16px);
    }
  }

  // styling buttons
  button.mat-mdc-unelevated-button,
  button.mat-mdc-outlined-button {
    border-radius: 10px;
    padding: 2px 24px;

    &.g-small {
      font-size: 14px;
    }

    &.g-medium {
      border-radius: 10px;
      font-size: 14px;
      line-height: 31px;
      padding: 2px 15px;
    }

    &.mat-mdc-outlined-button {
      background-color: white;
    }

    // override for button disabled state for accent color
    &.mat-accent.mat-mdc-unelevated-button[disabled] {
      color: $text-light-color;
      background-color: $accent;
      opacity: 0.5;
    }
  }

  // styling label
  label {
    color: $accent;
  }

  // not showing more than one mat-error at once
  mat-form-field mat-error:not(:first-of-type) {
    display: none;
  }

  // switching off animation
  mat-expansion-panel,
  mat-expansion-panel * {
    transition: none !important;
  }
  .mat-expansion-panel-header {
    padding: 0 20px;
  }
  .mat-expansion-panel-body {
    padding: 0 20px 40px;
  }
  mat-expansion-panel.mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0 3px 6px 0 rgba(0, 123, 168, 0.16);
  }

  // overrides for mat-card
  .mat-mdc-card {
    padding: 20px 20px 40px;
    color: $text-dark-color;
    background-color: $background-light-color;
    &:not([class*='mat-elevation-z']) {
      box-shadow: 0 3px 6px 0 rgba(0, 123, 168, 0.16);
    }
  }

  // overrides for mat-divider
  .mat-divider {
    border-top-color: #dddddd;
  }

  // overrides for mat-paginator
  .mat-mdc-paginator-container {
    align-items: baseline;
    color: $brownish-grey;
    background-color: $background-light-color;
  }

  // button-loader
  .button-loader {
    margin-right: 8px;
    vertical-align: middle;
    animation: rotation 1s infinite linear;
  }

  .g-menu .mat-mdc-menu-content,
  .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;

    &:hover:not([disabled]) {
      color: rgb(0, 123, 145);
      background-color: rgb(242, 248, 249);
    }
  }

  .g-accounts-selector-menu {
    width: 336px;
    max-width: unset;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: unset;
    @media screen and (max-width: 599px) {
      width: calc(100vw - 24px);
    }
    .mat-mdc-menu-content {
      padding: 0;
      .menu-item {
        border-bottom: 1px solid #dddddd;
        cursor: pointer;
        app-account-list-item {
          width: calc(100% - 26px);
        }

        .wallet-container {
          font-size: 16px;
          font-family: 'DM Sans', sans-serif;
          line-height: normal;

          .row {
            height: 38px;
            padding: 0 47px 0 16px;
            border-bottom: 1px solid #dddddd;
            strong {
              color: $accent;
            }
            img {
              margin-right: 12px;
            }
            &.wallet-header {
              padding-left: 0;
              background-color: #eeeeee;
              cursor: default;
              mat-icon {
                margin: 0 9px 0 13px;
                height: 30px;
                width: 30px;
              }
            }
          }
        }
      }
    }
  }

  .g-snackbar {
    padding: 0;
    .mat-mdc-snackbar-surface {
      box-shadow: none;
      background-color: transparent;
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}

.mat-mdc-icon-button {
  mat-icon {
    &[svgIcon='cancel'] {
      color: $accent;
      transform: scale(0.8);
    }
    &[svgIcon='add'] {
      transform: scale(0.92);
    }
  }
}

.mat-mdc-tooltip {
  font-size: 12px !important;
}
