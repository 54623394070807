@import './colors.scss';

ngx-intl-tel-input {
  font-family: 'Open Sans';
  .iti {
    margin-top: 0.25em;
    width: 100%;

    input {
      height: 56px !important; // same height as default height of mat-mdc-text-field-wrapper
      padding: 10px;
      width: 100%;
      border-radius: 5px;
      border: 1px solid $primary;
      font-family: 'Open Sans';

      &:focus {
        outline: none;
      }
    }

    .dropdown-menu.country-dropdown {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-color: $primary;
      margin-top: -1px;
    }

    .iti__country-list {
      white-space: normal;
      font-size: 14px;
      margin-left: 0;
      width: 228px;
      height: 150px;
    }

    .iti__flag-container.open + input {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .iti__flag-container.disabled {
      pointer-events: none;

      .iti__selected-flag {
        color: $disabled-color;
      }
    }

    .search-container input {
      border-radius: 0;
    }
  }

  input:hover:not(#country-search-box) {
    box-shadow: 0 0 0 1px $primary;
  }

  input:focus:not(#country-search-box) {
    outline: none;
    box-shadow:
      0 0 0 1px $primary,
      0 3px 6px 0 rgba(0, 0, 0, 0.3);
  }

  &.ng-invalid.ng-touched input:not(#country-search-box) {
    border: 1px solid $warn;
    box-shadow: 0 0 0 1px $warn;

    &:focus {
      box-shadow:
        0 0 0 1px $warn,
        0 3px 6px 0 rgba(0, 0, 0, 0.3);
    }
  }

  input[disabled] {
    background-color: white;
    &:hover:not(#country-search-box) {
      box-shadow: none;
    }
    color: $disabled-color;
  }
}
