$primary: #1aa5c3;
$accent: #007b91;
$warn: #e88181;

$jade: #0f656d;
$emerald: #09401f;
$onyx: #343233;
$ruby: #611348;

$text-dark-color: #454545;
$text-light-color: white;
$background-light-color: #fffdfa;
$gold-light: #e2ce79;
$gold-dark: #a59347;
$grey: #658d99;
$brownish-grey: #666666;
$red: #c12121;
$brown-grey: #999999;

$green-amount: #4caf50;
$red-amount: #f44336;

$disabled-color: rgba(0, 0, 0, 0.38);
$disabled-checkbox-color: #9e9d9b;
