$outline-color-primary: $primary;
$outline-color-accent: $accent;
$outline-color-warn: $warn;

// customize the border color of the leading, notch, and trailing parts of outlined mat-form-field
.mdc-text-field--outlined {
  .mat-mdc-form-field-flex {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $outline-color-primary !important;
    }
  }

  // focused form field
  .mdc-text-field--focused {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $outline-color-primary !important;
    }
  }
  :focus-within .mdc-notched-outline {
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    border-color: $outline-color-primary !important;
    border-width: 2px !important;
  }
  :focus-within .mdc-notched-outline__leading,
  :focus-within .mdc-notched-outline__trailing {
    border-color: $outline-color-primary !important;
    border-width: 2px !important;
  }
}

// invalid form field
.mat-form-field-invalid {
  .mdc-text-field--outlined {
    .mat-mdc-form-field-flex {
      .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: $outline-color-warn !important;
          border-width: 2px !important;
        }
      }
    }
  }
}

// customize input text inside mat-form-field
.mat-mdc-text-field-wrapper {
  margin-top: 5px;
  background-color: white;
  opacity: 1;
  border-radius: 5px !important;
}
