// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import './assets/scss/colors.scss';

$ecredo-primary-palette: (
  100: $primary,
  200: $primary,
  300: $primary,
  400: $primary,
  500: $primary,
  600: $primary,
  700: $primary,
  800: $primary,
  900: $primary,
  contrast: (
    500: $text-light-color,
  ),
);

$ecredo-accent-palette: (
  50: $accent,
  100: $accent,
  200: $accent,
  300: $accent,
  400: $accent,
  500: $accent,
  600: $accent,
  700: $accent,
  800: $accent,
  900: $accent,
  contrast: (
    500: $text-light-color,
  ),
);

$ecredo-warn-palette: (
  50: $warn,
  100: $warn,
  200: $warn,
  300: $warn,
  400: $warn,
  500: $warn,
  600: $warn,
  700: $warn,
  800: $warn,
  900: $warn,
  contrast: (
    500: $warn,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ecredo-front-client-primary: mat.m2-define-palette($ecredo-primary-palette);
$ecredo-front-client-accent: mat.m2-define-palette($ecredo-accent-palette);

// The warn palette is optional (defaults to red).
$ecredo-front-client-warn: mat.m2-define-palette($ecredo-warn-palette);

$ecredo-front-client-typography: mat.m2-define-typography-config(
  $font-family: '"Open Sans", sans-serif',
  $body-2:
    mat.m2-define-typography-level(
      $font-size: 12px,
      $line-height: 17px,
    ),
  $button:
    mat.m2-define-typography-level(
      $font-size: 20px,
      $font-weight: 700,
    ),
  $headline-5:
    mat.m2-define-typography-level(
      $font-family: '"DM Sans", sans-serif',
      $font-size: 30px,
      $line-height: 39px,
      $font-weight: 700,
    ),
  $headline-6:
    mat.m2-define-typography-level(
      $font-family: '"DM Sans", sans-serif',
      $font-size: 16px,
      $font-weight: 500,
    ),
  $body-1:
    mat.m2-define-typography-level(
      $font-size: 14px,
      $line-height: 1.36,
    ),
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ecredo-front-client-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $ecredo-front-client-primary,
      accent: $ecredo-front-client-accent,
      warn: $ecredo-front-client-warn,
    ),
    // typography: $ecredo-front-client-typography
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ecredo-front-client-theme);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($ecredo-front-client-typography);`
@include mat.all-component-typographies($ecredo-front-client-typography);
@include mat.core();

/* You can add global styles to this file, and also import other style files */

// overrides for mat-form-field
@import './assets/scss/form-field-outline-theme.scss';
@import './assets/scss/form-field-theme.scss';
// overrides for phone number input (ngx-intl-tel-input)
@import './assets/scss/ngx-intl-tel-input.scss';
// overrides for pdf viewer (ngx-extended-pdf-viewer)
@import './assets/scss/ngx-extended-pdf-viewer.scss';
// all other overrides
@import './assets/scss/overrides.scss';

.short-height {
  @include mat.all-component-densities(-4);
}
